/*---------Initial reset--------*/
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro');

html {
    box-sizing: border-box;
}

*,
*:before,
*:after {
    box-sizing: inherit;
}

body {
    margin: 0;
    font-family: Source Sans Pro, sans-serif;
}

input,
textarea,
select,
button {
    font-family: Source Sans Pro, sans-serif;
}

button:hover,
a {
    cursor: pointer;
}

/*--------Initial reset---------*/