.pagination-container {
    padding: 0;
    list-style-type: none;
    display: flex;
    height: 2em;
    justify-content: center;
    align-items: center;
}

.pagination-item {
    width: 2em;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 15px;
    height: 2em;
}

.pagination-item.disabled {
    color: lightgray;
}

.pagination-item.selected {
    border: 1px solid;
    border-radius: 5px;
}

.pagination-anchor {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
}