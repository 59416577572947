@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro);
.pagination-container {
    padding: 0;
    list-style-type: none;
    display: flex;
    height: 2em;
    justify-content: center;
    align-items: center;
}

.pagination-item {
    width: 2em;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 15px;
    height: 2em;
}

.pagination-item.disabled {
    color: lightgray;
}

.pagination-item.selected {
    border: 1px solid;
    border-radius: 5px;
}

.pagination-anchor {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
}
/*---------Initial reset--------*/

html {
    box-sizing: border-box;
}

*,
*:before,
*:after {
    box-sizing: inherit;
}

body {
    margin: 0;
    font-family: Source Sans Pro, sans-serif;
}

input,
textarea,
select,
button {
    font-family: Source Sans Pro, sans-serif;
}

button:hover,
a {
    cursor: pointer;
}

/*--------Initial reset---------*/
